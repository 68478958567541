import React from 'react';
import '../scss/header.scss';
import { Link } from 'react-router-dom';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.updateActiveState = this.updateActiveState.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.updateActiveState();
  }
  updateActiveState() {
    setTimeout(function() {
      var locationPath = window.location.pathname.replace('/', '').substr(1).split('/');
      document.querySelectorAll('.main-nav li').forEach((item) => {
        item.classList.remove('active');
      });
      if (document.querySelector('.main-nav a[href*="'+ locationPath[0] +'"]')) {
        document.querySelector('.main-nav a[href*="'+ locationPath[0] +'"]').parentElement.classList.add('active');
      } else {
        document.querySelector('.main-nav li:first-child').classList.add('active');
      }

      document.querySelector('.header-top .nav').classList.remove('active');
      window.scrollTo(0,0);
    }, 100);
  }

  toggleMenu() {
    if (document.querySelector(".hb-menu").offsetWidth && document.querySelector(".hb-menu").offsetHeight) {
      var $list = document.querySelector('.header-top .nav').classList;
      if (!$list.contains('active')) {
        document.querySelector('.header-top .nav').classList.add('active');
      } else {
        document.querySelector('.header-top .nav').classList.remove('active');
      }
    }
  }

  render() {
    return (
      <div className='header'>
          <div className='container'>
            <div className='py-4 header-top'>
              <div className='nav d-flex justify-content-between align-items-center'>
                  <div className='logo'>
                    <Link onClick={this.updateActiveState} to="/" >
                      <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt='Millennium' />
                    </Link>
                  </div>
                  <div className='nav-overlay'>
                    <ul className="d-flex text-roboto m-0 py-0 px-6 pb-6 pb-lg-0 main-nav">
                        <li><Link onClick={this.updateActiveState} to="/" className="fs-18 fw-700 color-blue">Home</Link></li>
                        <li><Link onClick={this.updateActiveState} to="/aboutus" className="fs-18 fw-700 color-blue">About Us</Link></li>
                        <li className='header-dropdown-item'>
                          <Link onClick={this.updateActiveState} to="/services/consulting" className="fs-18 fw-700 color-blue">Services</Link>
                          <div className='header-dropdown-menu'>
                            <Link onClick={this.updateActiveState} to="/services/consulting" className="fs-12 fs-xl-18 fw-700 color-blue">Consulting Services</Link>
                            <Link onClick={this.updateActiveState} to="/services/staffing" className="fs-12 fs-xl-18 fw-700 color-blue">Staffing Services</Link>
                            <Link onClick={this.updateActiveState} to="/services/training" className="fs-12 fs-xl-18 fw-700 color-blue">Training Services</Link>
                          </div>
                        </li>
                        <li><Link onClick={this.updateActiveState} to="/carriers" className="fs-18 fw-700 color-blue">Carriers & Benefits</Link></li>
                        <li><Link onClick={this.updateActiveState} to="/jobs" className="fs-18 fw-700 color-blue">Hot Jobs</Link></li>
                        <li><Link onClick={this.updateActiveState} to="/contactus" className="fs-18 fw-700 color-blue">Contact Us</Link></li>
                    </ul>
                  </div>
                  <button className="hb-menu d-lg-none" onClick={this.toggleMenu}><div></div><div></div><div></div></button>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default Header;
