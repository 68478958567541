import Header from '../components/Header';
import Footer from '../components/Footer';
import { Outlet } from "react-router-dom";

function Layout(props) {
  return (
    <div className='wrapper'>
      <header className='position-relative'><Header /></header>
      <main><Outlet /></main>
      <footer><Footer footerData={props.siteData}/></footer>
      <div id="timer" className='d-none'></div>
    </div>
  );
}

export default Layout;
