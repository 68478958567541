import Parse from 'html-react-parser';

const Jobs = (props) => {
    return (
        <>
            {props.siteData && props.siteData.jobs &&
                <div>
                    <div className="page-banner">
                        <img src={props.siteData.jobs.banner} alt="jobs" className="max-width-100"/>
                    </div>
                    <div className="page-bg pt-12">
                        <div className="container text-justify page-container">
                            {props.siteData.jobs.title &&
                                <h1 className="color-blue text-roboto fw-700 fs-36 text-center">
                                    {Parse(props.siteData.jobs.title)}
                                </h1>
                            }
                            <div className="row mt-12 mb-6 flex-md-row-reverse justify-content-center">
                                <div className="col-12 col-sm-8 col-md-6 mb-6 mb-md-0" >
                                    <div className="position-relative fancy-radius p-3 bg-primary-alt">
                                        <div className="dot-shape-top position-absolute">
                                            <img src={process.env.PUBLIC_URL + '/images/color-shape.svg'} alt="dot" className="img-fluid" />
                                        </div>
                                        <div className="as-1x1">
                                            <img className="fancy-radius img-fluid" src={props.siteData.jobs.section1.img} alt="modern desk" />
                                        </div>
                                        <div className="dot-shape position-absolute bottom-0">
                                            <img src={process.env.PUBLIC_URL + '/images/dot-shape.png'} alt="dot" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ul className="jobs-list pr-5 text-roboto">
                                        {props.siteData.jobs.section1.jobs.map((job, i) => (
                                            <li key={job} className={'py-2 px-3 d-flex justify-content-between ' + (i%2 === 1 ? 'bg-body-secondary' : '')}>
                                                <span><a className="text-decoration-none" href={'mailto:' + props.siteData.jobs.section1.mail + '?body=' + job.exp}>{job.title}</a></span>
                                                <span><a className="text-decoration-none" href={'mailto:' + props.siteData.jobs.section1.mail + '?body=' + job.exp}>{job.exp}</a></span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Jobs;