import '../scss/launch.scss';
import React from 'react';
import { Link } from 'react-router-dom';

class Launch extends React.Component {
    componentDidMount(){
        // Set the date we're counting down to
        var countDownDate = new Date("August 5, 2023").getTime();
            
        // Update the count down every 1 second
        var x = setInterval(function() {
        
          // Get todays date and time
          var now = new Date().getTime();
        
          // Find the distance between now an the count down date
          var distance = countDownDate - now;
        
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
          // Display the result in an element with id="demo"
          document.getElementById("timer").innerHTML = days + "d - " + hours + "h - "
          + minutes + "m - " + seconds + "s ";
        
          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x);
            document.getElementById("timer").innerHTML = "00 : 00 : 00";
          }
        }, 1000);
    }
    render() {
        return (
            <div className="launch">
                <article>
                    <div class="launch-logo">
                        <Link to="/demo">
                            <img src={process.env.PUBLIC_URL + '/images/Millennium-Logo.svg'} alt="logo" />
                            Consulting Services
                        </Link>
                    </div>
                    <div id="timer"></div>
                    <h1>Our new site is coming soon!!!</h1>
                    <p>Stay tuned for something amazing</p>
                    <address>
                        22 Woodlands Park, Spit fire street, sunning hill, Sandton - 2191
                    </address>
                    <address>
                        Call Us: <a href="tel:+270609077509">+27 0609077509</a>, <a href="tel:+270609077509">+27 0729888527</a>
                    </address>
                    <address>
                        Email Us: <a href="mailto:hr@millenniumtech.co.za">hr@millenniumtech.co.za</a>
                    </address>
                </article>
            </div>
        );
    }
};

export default Launch;