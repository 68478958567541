import { useParams } from "react-router-dom";
import Consulting from "./Consulting";
import Staffing from "./Staffing";
import Training from "./Training";

const Services = (props) => {
    let { id } = useParams();
  return (
      <div className={id}>
          <div className="page-banner">
              <img className="of-center" src={process.env.PUBLIC_URL + '/images/banners/'+ id +'-banner.jpg'} alt="Who we are" />
              <h1 className="page-title fs-40 fs-sm-80 fw-600 text-capitalize text-center">{id} Services</h1>
          </div>
          <div className="page-bg pt-12">
              <div className="container page-container">
                {id === 'consulting' && <Consulting siteData={props.siteData}/>}
                {id === 'staffing' && <Staffing siteData={props.siteData}/>}
                {id === 'training' && <Training siteData={props.siteData}/>}
              </div>
          </div>
      </div>
  );
};

export default Services;