import HomepageSlider from "../components/HomepageSlider";
import OurCustomers from "../components/OurCustomers";
import "../scss/homepage.scss";
import '../scss/global.scss';
import Parse from 'html-react-parser';

const Home = (props) => {
  return (
    <div className="home-page">
      {props.siteData && props.siteData.homePage && <HomepageSlider images={props.siteData.homePage.banners}/> }
      {props.siteData && props.siteData.homePage &&
        <div className="page-bg">
          <div className="container">
            <div className="home-title py-5 pt-md-10 text-center">
              {props.siteData.homePage.section1.title &&
                <h1 className="color-blue text-roboto fw-700 fs-36">
                  {Parse(props.siteData.homePage.section1.title)}
                </h1>
              }
              {props.siteData.homePage.section1.desc &&
                <p className="fs-18 m-auto mb-8">
                  {props.siteData.homePage.section1.desc}
                </p>
              }
            </div>

            {props.siteData.homePage.section2 &&
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 py-4 py-lg-0">
                  {props.siteData.homePage.section2.img &&
                    <div className="position-relative fancy-radius p-3 bg-primary-alt">
                        <div className="dot-shape-top position-absolute">
                            <img src={process.env.PUBLIC_URL + '/images/color-shape.svg'} alt="dot" className="img-fluid" />
                        </div>
                        <div className="as-1x1">
                          <img className="fancy-radius img-fluid" src={props.siteData.homePage.section2.img} alt="modern desk" />
                        </div>
                        <div className="dot-shape position-absolute bottom-0">
                            <img src={process.env.PUBLIC_URL + '/images/dot-shape.png'} alt="dot" />
                        </div>
                    </div>
                  }
                </div>
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  {props.siteData.homePage.section2.title &&
                    <h2 className="fs-30 text-roboto fs-700 pb-2 color-blue">{Parse(props.siteData.homePage.section2.title)}</h2>
                  }
                  {props.siteData.homePage.section2.desc &&
                    <p className="fs-18 lh-32 text-justify">
                      {Parse(props.siteData.homePage.section2.desc)}
                    </p>
                  }
                </div>
              </div>
            }

            {props.siteData.homePage.section3 &&
              <div>
                {props.siteData.homePage.section3.title &&
                  <div className="row pt-12 pb-6">
                    <div className="col-12">
                      <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 text-center">
                        {Parse(props.siteData.homePage.section3.title)}
                      </h2>
                    </div>
                  </div>
                }
                <div className="row pb-12 justify-content-center">
                  {props.siteData.homePage.section3.desc &&
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center pe-12">
                      {props.siteData.homePage.section3.desc.title && <h2 className="fs-24 fw-700 pb-2 color-blue m-0 pb-6">{Parse(props.siteData.homePage.section3.desc.title)}</h2>}
                      {props.siteData.homePage.section3.desc.info && <p className="fs-18 lh-32 text-justify">{Parse(props.siteData.homePage.section3.desc.info)}</p>}
                      {props.siteData.homePage.section3.desc.list &&
                        <ul className="list-with-numbers fs-20 fw-700">
                          {props.siteData.homePage.section3.desc.list.map(item => (
                            <p className="fs-18 lh-32 text-justify" key={item}>{Parse(item)}</p>
                          ))}
                        </ul>
                      }
                    </div>
                  }
                  <div className="col-12 col-sm-8 col-md-6">
                    <div className="position-relative fancy-radius p-3 bg-primary-alt">
                        <div className="dot-shape-top position-absolute">
                            <img src={process.env.PUBLIC_URL + '/images/color-shape.svg'} alt="dot" className="img-fluid" />
                        </div>
                        <div className="as-1x1">
                          <img className="fancy-radius img-fluid" src={Parse(props.siteData.homePage.section3.img)} alt="modern desk" />
                        </div>
                        <div className="dot-shape position-absolute bottom-0">
                            <img src={process.env.PUBLIC_URL + '/images/dot-shape.png'} alt="dot" />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {props.siteData.homePage.section4 &&
              <div>
                <div className="row pt-12 pb-6">
                  <div className="col-12">
                    <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 text-center">
                      {Parse(props.siteData.homePage.section4.title)}
                    </h2>
                  </div>
                </div>
                {props.siteData.homePage.section4.list && props.siteData.homePage.section4.list.length &&
                  <div className="row">
                    {props.siteData.homePage.section4.list.map(item => (
                      <div className="col-12 col-md-6 px-6 px-md-12 mt-6" key={item}>
                        <h3 className="fw-700 fs-16 fs-md-24 text-outline">{Parse(item.title)}</h3>
                        <p className="fs-16 lh-24 text-justify">
                          {Parse(item.desc)}
                        </p>
                      </div>
                    ))}
                  </div>
                }
              </div>
            }

            <OurCustomers customers={props.siteData.homePage.customers}/>

          </div>
        </div>
      }
    </div>
  );
};

export default Home;
