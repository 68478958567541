import Parse from 'html-react-parser';
const Training = (props) => {
    return (
        <>
            {props.siteData && props.siteData.training &&
                <div>
                    {props.siteData.training.section1.title && <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 mb-6 text-center">
                        {Parse(props.siteData.training.section1.title)}
                    </h2>}

                    {props.siteData.training.section1.desc && <div className="m-auto text-justify">
                        {props.siteData.training.section1.desc.map((item) => (
                            <p key={item}>{Parse(item)}</p>
                        ))}
                    </div>}
                    <div className="row mt-12 justify-content-center flex-row-sm-reverse">
                        <div className="col-12 col-sm-8 col-md-6 d-flex flex-column justify-content-lg-center">
                            {props.siteData.training.section2.img && <div className="image-box fancy-radius p-2 bg-primary-alt mb-6">
                                <div className="as-1x1">
                                    <img src={props.siteData.training.section2.img} alt="Why us" className="fancy-radius img-fluid"/>
                                </div>
                            </div>}
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                            {props.siteData.training.section2.title && <h3 className="fs-18 fw-700 mb-4">
                                {Parse(props.siteData.training.section2.title)}
                            </h3>}

                            {props.siteData.training.section2.list && <ul className="fs-16 fw-500 p-0 m-0 ps-4 mb-6">
                                {props.siteData.training.section2.list.map((item) => (
                                    <li className="mb-4" key={item}>{Parse(item)}</li>
                                ))}
                            </ul>}
                            {props.siteData.training.section2.desc && props.siteData.training.section2.desc.map((item) => (
                                <p key={item}>{Parse(item)}</p>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Training;