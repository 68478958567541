import React from 'react';
import '../scss/footer.scss';
import { Link } from 'react-router-dom';
import Parse from 'html-react-parser';

const Footer = (props) => {
  return (
    <div className='footer pt-8 ls-3'>
        <div className='container mb-8 footer-top'>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <div className='logo'><img src={process.env.PUBLIC_URL + '/images/logo-white.svg'} alt='Millennium' /></div>
              <address className='m-0 text-roboto'>
                {props.footerData && props.footerData.footer && props.footerData.footer.address &&
                  <div className='my-4'>{Parse(props.footerData.footer.address)}</div>
                }
                {props.footerData && props.footerData.footer && props.footerData.footer.call &&
                  <div className='my-4'>{Parse(props.footerData.footer.call)}</div>
                }
                {props.footerData && props.footerData.footer && props.footerData.footer.email &&
                  <div className='my-4'>{Parse(props.footerData.footer.email)}</div>
                }
              </address>
            </div>
            <div className='col-12 col-sm-6 d-flex justify-content-start justify-content-md-end'>
              <ul className="text-roboto p-0 m-0 py-5 pe-6 me-6 pe-sm-12 me-sm-12">
                  <li className="active"><Link to="/" className="fs-14">Home</Link></li>
                  <li><Link to="/aboutus" className="fs-14">About Us</Link></li>
                  <li><Link to="/services/consulting" className="fs-14">Services</Link></li>
                  <li><Link to="/carriers" className="fs-14">Carriers & Benefits</Link></li>
              </ul>
              <ul className="text-roboto p-0 m-0 py-5">
                  <li className="active"><Link to="/" className="fs-14">Contact us</Link></li>
                  <li><Link to="/aboutus" className="fs-14">Terms</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-bottom py-4'>
          <div className='container'>
              <div className='row'>
                <div className='col-12 col-sm-6'>
                  {props.footerData && props.footerData.footer && Parse(props.footerData.footer.copyright)}
                </div>
                <div className='col-12 col-sm-6 footer-bottom-social text-start text-sm-end mt-4 mt-sm-0'>
                  {props.footerData && props.footerData.footer && props.footerData.footer.social.map(socialData => (
                    <Link to={socialData.url} key={socialData}><img src={socialData.img} alt={socialData.alt} /></Link>
                  ))}
                </div>
              </div>
          </div>
        </div>
    </div>
  );
}

export default Footer;
