import Parse from 'html-react-parser';
import { Link } from "react-router-dom";

const Contactus = (props) => {
    return (
        <>
            {props.siteData && props.siteData.contact &&
                <div>
                    <div className="page-banner">
                        <img src={props.siteData.contact.banner} alt="Who we are" className="max-width-100"/>
                        <h1 className="page-title fs-40 fs-sm-80 fw-600 text-center">
                            {Parse(props.siteData.contact.title)}
                        </h1>
                    </div>
                    {props.siteData.contact.section1 &&
                        <div className="page-bg pt-12">
                            <div className="container text-justify page-container">
                                <div className="row mt-12 mb-6 justify-content-center">
                                    <div className="col-12 col-sm-8 col-md-6 mb-6 mb-md-0">
                                        {props.siteData.contact.section1.img &&
                                            <div className="position-relative fancy-radius p-3 bg-primary-alt">
                                                <div className="dot-shape-top position-absolute">
                                                    <img src={process.env.PUBLIC_URL + '/images/color-shape.svg'} alt="dot" className="img-fluid" />
                                                </div>
                                                <div className="as-1x1">
                                                    <img className="fancy-radius img-fluid" src={props.siteData.contact.section1.img} alt="" />
                                                </div>
                                                <div className="dot-shape position-absolute bottom-0">
                                                    <img src={process.env.PUBLIC_URL + '/images/dot-shape.png'} alt="dot" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                        {props.siteData.contact.section1.reach &&
                                            <div className="contact-card fs-18 fw-500 ls-1">
                                                <h4 className="fs-20 text-uppercase text-roboto fw-800 color-secondary">
                                                    {Parse(props.siteData.contact.section1.reach.title)}
                                                </h4>
                                                {Parse(props.siteData.contact.section1.reach.desc)}
                                            </div>
                                        }
                                        {props.siteData.contact.section1.dial &&
                                            <div className="contact-card fs-18 mt-6">
                                                <h4 className="fs-20 text-uppercase text-roboto fw-800 color-secondary">
                                                    {Parse(props.siteData.contact.section1.dial.title)}
                                                </h4>
                                                {props.siteData.contact.section1.dial.list.map((item) => (
                                                    <div key={item}><Link className="text-decoration-none" to={"tel:" + item}>{item}</Link></div>
                                                ))}
                                            </div>
                                        }

                                        {props.siteData.contact.section1.email &&
                                            <div className="contact-card fs-18 mt-6">
                                                <h4 className="fs-20 text-uppercase text-roboto fw-800 color-secondary">
                                                    {Parse(props.siteData.contact.section1.email.title)}
                                                </h4>
                                                {props.siteData.contact.section1.email.list.map((item) => (
                                                    <div key={item}><Link className="text-decoration-none" to={"mailto:" + item}>{item}</Link></div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default Contactus;