import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Services from "./pages/Services";
import Carriers from "./pages/Carriers";
import Jobs from "./pages/Jobs";
import Contactus from "./pages/Contactus";
import Launch from "./pages/Launch";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {siteData: {}};
  }
  componentDidMount () {
    fetch(`${process.env.PUBLIC_URL}/siteData.json`)
      .then((response) => response.json())
      .then(response => {
        this.setState({
          siteData: response
        });
    });
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout siteData={this.state.siteData} />}>
              <Route index element={<Home siteData={this.state.siteData}/>} />
              <Route path="aboutus" element={<Aboutus siteData={this.state.siteData}/>}  />
              <Route path="services/:id" element={<Services siteData={this.state.siteData}/>} />
              <Route path="carriers" element={<Carriers siteData={this.state.siteData}/>}  />
              <Route path="jobs" element={<Jobs siteData={this.state.siteData}/>}  />
              <Route path="contactus" element={<Contactus siteData={this.state.siteData}/>}  />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
