import Parse from 'html-react-parser';

const Carriers = (props) => {
    return (
        <>
            {props.siteData && props.siteData.carriers &&
                <div>
                    <div className="page-banner">
                        <img src={props.siteData.carriers.banner} alt="Who we are" className="max-width-100"/>
                        <h1 className="page-title fs-40 fs-sm-80 fw-600 text-center">
                            {Parse(props.siteData.carriers.title)}
                        </h1>
                    </div>
                    <div className="page-bg pt-12">
                        <div className="container text-justify page-container">
                            <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 mb-6 text-center">
                                {Parse(props.siteData.carriers.section1.title)}
                            </h2>

                            {props.siteData.carriers.section1.desc.map((item) => (
                                <p key={item}>{Parse(item)}</p>
                            ))}

                            {props.siteData.carriers.section2 &&
                                <div className="row mt-12 mb-6 flex-md-row-reverse justify-content-center">
                                    <div className="col-12 col-sm-8 col-md-6">
                                        <div className="image-box fancy-radius p-2 bg-primary-alt">
                                            <div className="as-1x1">
                                                <img className="fancy-radius img-fluid" src={props.siteData.carriers.section2.img} alt="modern desk" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {props.siteData.carriers.section2.desc.map((item) => (
                                            <div key={item}>
                                                <h3 className="fs-20 fw-700 text-outline">{Parse(item.title)}</h3>
                                                <p>{Parse(item.desc)}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {props.siteData.carriers.section3 &&
                                <div className="row mt-12 mb-6 justify-content-center">
                                    <div className="col-12 col-sm-8 col-md-6">
                                        <div className="image-box fancy-radius p-2 bg-primary-alt">
                                            <div className="as-1x1">
                                                <img className="fancy-radius img-fluid" src={props.siteData.carriers.section3.img} alt="modern desk" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {props.siteData.carriers.section3.desc.map((item) => (
                                            <div key={item}>
                                                <h3 className="fs-20 fw-700 text-outline">{Parse(item.title)}</h3>
                                                <p>{Parse(item.desc)}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {props.siteData.carriers.section4 &&
                                <div>
                                    {props.siteData.carriers.section4.desc.map((item) => (
                                        <p key={item}>{Parse(item)}</p>
                                    ))}
                                    <p className="color-blue text-roboto fw-700 fs-24 mb-6 text-center">
                                        {Parse(props.siteData.carriers.section4.caption)}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Carriers;