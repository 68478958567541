import Parse from 'html-react-parser';

const Consulting = (props) => {
    return (
        <>
            {props.siteData && props.siteData.consulting &&
                <div>
                    {props.siteData.consulting.section1 &&
                        <div>
                            {props.siteData.consulting.section1.title &&
                                <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 mb-6 text-center">
                                    {Parse(props.siteData.consulting.section1.title)}
                                </h2>
                            }
                            {props.siteData.consulting.section1.desc &&
                                <p className="text-center">{Parse(props.siteData.consulting.section1.desc)}</p>
                            }
                        </div>
                    }

                    {props.siteData.consulting.section2 &&
                        <div className="row mt-12 justify-content-center">
                            <div className="col-12 col-md-6">
                                {props.siteData.consulting.section2.list.map(listItem => (
                                    <div key={listItem}>
                                        <h3 className="fs-20 fw-700 text-outline">{Parse(listItem.title)}</h3>
                                        <p>{Parse(listItem.desc)}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 col-sm-8 col-md-6">
                                <div className="image-box fancy-radius p-2 bg-primary-alt">
                                    <div className="as-1x1">
                                        <img src={props.siteData.consulting.section2.img} alt="Why us" className="fancy-radius img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {props.siteData.consulting.section3 &&
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h3 className="fs-20 fw-700 text-outline">{Parse(props.siteData.consulting.section3.title)}</h3>
                                {props.siteData.consulting.section3.desc.map((item) => (
                                    <p key={item}>{Parse(item)}</p>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default Consulting;