import React from 'react';
import Slider from 'react-slick';
import Parse from 'html-react-parser';
import '../scss/slick.scss';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block'}}
      onClick={onClick}
    ><img src={process.env.PUBLIC_URL + '/images/chevron_right.svg'} alt='Next' /></div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block'}}
      onClick={onClick}
    ><img src={process.env.PUBLIC_URL + '/images/chevron_left.svg'} alt='Prev' /></div>
  );
}


const HomepageSlider = (props) => {
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    fade: true,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
    return (
      <div className='hero-banner'>
        <Slider {...settings}>
          {props.images.map(banner => (
            <div key={banner}>
              <div style={banner.styles} className='slide-item'>
                <picture>
                  <source media="(min-width:768px)" srcSet={banner.img} />
                  <img src={banner.imgM} alt={banner.title}/>
                </picture>
                <div className={banner.textAlign + " slide-item-overlay text-roboto"}>
                  {banner.title.split(' ').map(titleText => (
                    <span className='text-blur' key={titleText}>{Parse(titleText)} </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

export default HomepageSlider;