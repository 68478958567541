import React from 'react';
import Slider from 'react-slick';
import '../scss/slick.scss';
import Parse from 'html-react-parser';

const OurCustomers = (props) => {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <div className='our-customers'>
            <div className="row pt-12 pb-6">
                <div className="col-12">
                    <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 text-center">
                        {Parse(props.customers.title)}
                    </h2>
                </div>
            </div>
            <Slider className='our-customers-slick p-3 p-lg-6' {...settings}>
                {props.customers.list.map(customer => (
                    <div className='our-customers-slick-item' key={customer}>
                        <img src={customer} alt='client'/>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default OurCustomers;
