import Parse from 'html-react-parser';

const Aboutus = (props) => {
    return (
        <>
            {props.siteData && props.siteData.aboutus &&
                <div>
                    <div className="page-banner">
                        <img src={props.siteData.aboutus.banner} alt="Who we are" className="max-width-100"/>
                        <h1 className="page-title fs-40 fs-sm-80 fw-600 text-center">
                            {Parse(props.siteData.aboutus.title)}
                        </h1>
                    </div>
                    <div className="page-bg pt-12">
                        <div className="container text-justify page-container">
                            {props.siteData.aboutus.section1 &&
                                <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 mb-6 text-center">
                                    {Parse(props.siteData.aboutus.section1.title)}
                                </h2>
                            }
                            {props.siteData.aboutus.section2 &&
                                <div className="row mt-12 mb-6 justify-content-center">
                                    <div className="col-12 col-md-6">
                                        {props.siteData.aboutus.section2.desc.map((item) => (
                                            <p key={item}>{Parse(item)}</p>
                                        ))}
                                    </div>
                                    <div className="col-12 col-sm-8 col-md-6">
                                        <div className="position-relative fancy-radius p-3 bg-primary-alt">
                                            <div className="dot-shape-top position-absolute">
                                                <img src={process.env.PUBLIC_URL + '/images/color-shape.svg'} alt="dot" className="img-fluid" />
                                            </div>
                                            <div className="as-1x1">
                                                <img className="fancy-radius img-fluid" src={props.siteData.aboutus.section2.img} alt={props.siteData.aboutus.section2.alt} />
                                            </div>
                                            <div className="dot-shape position-absolute bottom-0">
                                                <img src={process.env.PUBLIC_URL + '/images/dot-shape.png'} alt="dot" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div>
                                {props.siteData.aboutus.section3.desc.map((item) => (
                                    <p key={item}>{Parse(item)}</p>
                                ))}
                            </div>
                            {props.siteData.aboutus.section4 &&
                                <div>
                                    <h3 className="fw-700 fs-24 text-outline">{Parse(props.siteData.aboutus.section4.title)}</h3>
                                    <p className="fs-16 lh-24 text-justify">{Parse(props.siteData.aboutus.section4.desc)}</p>
                                </div>
                            }
                            {props.siteData.aboutus.section5 &&
                                <div>
                                    <h3 className="fw-700 fs-24 text-outline">{Parse(props.siteData.aboutus.section5.title)}</h3>
                                    <p className="fs-16 lh-24 text-justify">{Parse(props.siteData.aboutus.section5.desc)}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Aboutus;