import Parse from 'html-react-parser';

const Staffing = (props) => {
    return (
        <>
            {props.siteData && props.siteData.staffing &&
                <div>
                    {props.siteData.staffing.section1.title &&
                        <h2 className="color-blue text-roboto fw-700 fs-24 fs-md-36 mb-6 text-center">
                            {Parse(props.siteData.staffing.section1.title)}
                        </h2>
                    }

                    <div className="w-75 m-auto text-center">
                        {props.siteData.staffing.section1.desc &&
                            <p className="text-center">
                                {Parse(props.siteData.staffing.section1.desc)}
                            </p>
                        }

                        <div>
                            {props.siteData.staffing.section2.title && <h3 className="fs-20 fw-700">{Parse(props.siteData.staffing.section2.title)}</h3>}
                            {props.siteData.staffing.section2.desc && <p>{Parse(props.siteData.staffing.section2.desc)}</p>}
                        </div>
                    </div>
                    <div className="row mt-8 justify-content-center">
                            <div className="col-12 col-sm-8 col-md-6 d-flex flex-column justify-content-lg-center">
                                <div className="image-box fancy-radius p-2 bg-primary-alt  mb-6">
                                    <div className="as-1x1">
                                        <img src={props.siteData.staffing.section3.img} alt="Why us" className="fancy-radius img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                <h2 className="color-blue text-roboto fw-700 fs-30 mb-6">
                                    {Parse(props.siteData.staffing.section3.title)}
                                </h2>
                                {props.siteData.staffing.section3.list.map((listItem3) => (
                                    <p key={listItem3}>
                                        <b>{Parse(listItem3.title)}</b> {Parse(listItem3.desc)}
                                    </p>
                                ))}
                            </div>
                        </div>
                    <div className="row mt-12">
                        <div className="col-12 d-flex flex-column align-items-center mt-8">
                            <h2 className="color-blue text-roboto fw-700 fs-30 mb-6 text-center">{Parse(props.siteData.staffing.section4.title)}</h2>
                            <p className="text-center w-75">
                            {Parse(props.siteData.staffing.section4.desc)}
                            </p>
                        </div>
                        <div className="col-12 mt-8">
                            <div className="row">
                                {props.siteData.staffing.section4.list.map((listItem4) => (
                                    <div className="col-12 col-md-6" key={listItem4}>
                                        <h3 className="fs-20 fw-700 text-outline">
                                            {Parse(listItem4.title)}
                                        </h3>
                                        <p>{Parse(listItem4.desc)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                    </div>
                </div>
            }
        </>
    );
};

export default Staffing;